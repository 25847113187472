<template>
  <div class="wrapper">
    <menu-ver type="profesor" :content="courses" v-on:theme="selectTheme" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          style="float: none"
          @click="$router.go(-1)"
        >
          <i class="fas fa-undo"></i> {{ $t("actions.return") }}
        </button>
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="blocks && blocks.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.key") }}</th>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in blocks" :key="item.bloque_id">
            <td>{{ item.bloque_id }}</td>
            <td>{{ item.bloque_titulo }}</td>
            <td style="min-width: 80pt;">
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.blocks.addTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="bloque_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.description`)
              }}</label>
              <textarea
                v-model.trim="bloque_descripcion"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`phases.type`) }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="bloque_tipo"
              >
                <option value="PDF">PDF</option>
                <option value="VIDEO">VIDEO</option>
                <option value="OFFICE">OFFICE</option>
                <option value="EXAM">EXAM</option>
              </select>
            </div>
            <div class="mb-3" v-if="bloque_tipo === 'EXAM'">
              <label class="form-label">{{
                $t(`maintenance.blocks.typeexam`) + " *"
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="bloque_examen_cd"
              >
                <option
                  v-for="ex in exams"
                  :key="ex.exam_id"
                  :value="ex.exam_id"
                >
                  {{ ex.exam_titulo }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <FilePond
                ref="pondAdd"
                :label-idle="imgText"
                class="imgFile"
                max-files="1"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addblock"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.blocks.deleteTitle", { name: `${blockId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.blocks.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteblock"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.blocks.editTitle", { name: `${blockId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="exampleDropdownFormName"
                v-model.trim="bloque_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.description`)
              }}</label>
              <textarea
                v-model.trim="bloque_descripcion"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`phases.type`) }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="bloque_tipo"
              >
                <option value="PDF">PDF</option>
                <option value="VIDEO">VIDEO</option>
                <option value="OFFICE">OFFICE</option>
                <option value="EXAM">EXAM</option>
              </select>
            </div>
            <div class="mb-3" v-if="bloque_tipo === 'EXAM'">
              <label class="form-label">{{
                $t(`maintenance.blocks.typeexam`) + " *"
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="bloque_examen_cd"
              >
                <option
                  v-for="ex in exams"
                  :key="ex.exam_id"
                  :value="ex.exam_id"
                >
                  {{ ex.exam_titulo }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <FilePond
                ref="pondEdit"
                :label-idle="imgText"
                class="imgFile"
                max-files="1"
              />
            </div>
            <a
              class="btn button buttonEdit"
              target="_blank"
              :href="`${servidor}api/ver_archivo_bloque.asp?bloque_id=${bloque_id}`"
              style="margin-right: 5pt; margin-left: 5pt"
            >
              <i class="fas fa-download"></i>
            </a>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editblock"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond
  },
  data() {
    return {
      blocks: [],
      block: {},
      bloque_titulo: "",
      bloque_id: "",
      blockId: "",
      result: "",
      action: 0,
      bloque_tema_cd: this.$route.params.id,
      formError: false,
      loading: false,
      bloque_descripcion: "",
      bloque_tipo: "",
      exams: [],
      bloque_examen_cd: "",
      imgText: this.$t("actions.imgText"),
      courses: []
    };
  },
  created() {
    this.getblocks();
    this.changeBreadCrumb(17);
    this.bigDrop();
    this.getExams();
    this.getcourses();
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado`
        );
        // console.log("miscursos", res);
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getblocks() {
      try {
      } catch (error) {
        console.error(error);
      }
      const lista = {
        bloque_tema_cd: this.bloque_tema_cd,
      };
      const res = await axios.post(
        `${this.servidor}api/op.asp?tp=blocks&ac=listado`,
        lista
      );
      if (res.data) {
        this.blocks = res.data.data;
      }
    },
    async getExams() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=exam&ac=listado`
        );
        // console.log(res);
        if (res.data) {
          this.exams = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addblock() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=blocks&ac=grabar`,
            this.formatDatas()
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }      
    },
    async editblock() {
      this.result = "0";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=blocks&ac=actualizar`,
            this.formatDatas()
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          this.result = "1";
          console.error(error);
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteblock() {
      try {
        const borrado = {
          bloque_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=blocks&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.blockId = event[0];
    },
    validateForm() {
      // console.log("invalidate", this.bloque_tipo);
      if (
        this.bloque_titulo === "" ||
        this.bloque_descripcion === "" ||
        this.bloque_tipo === "" ||
        this.bloque_tipo === null
      ) {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.bloque_id = "";
      this.bloque_titulo = "";
      this.bloque_descripcion = "";
      this.bloque_tipo = "";
      this.bloque_examen_cd = "";
      this.loading = false;
      this.formError = false;
      if (this.$refs.pondAdd) {
        this.$refs.pondAdd.removeFile(0);
      }
      if (this.$refs.pondEdit) {
        this.$refs.pondEdit.removeFile(0);
      }
      this.getblocks();
    },
    getActions(act, item) {
      this.formError = false;
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.block = item;
        this.bloque_id = item.bloque_id;
        this.bloque_titulo = item.bloque_titulo;
        this.bloque_descripcion = item.bloque_descripcion;
        this.bloque_tipo = item.bloque_tipo;
        this.bloque_examen_cd = item.bloque_examen_cd;
        return;
      }
      if (item.bloque_id) {
        this.id = item.bloque_id;
      }
    },
    formatDatas() {
      let formData = new FormData();

      formData.append("bloque_id", this.bloque_id);
      formData.append("bloque_titulo", this.bloque_titulo.toUpperCase());
      formData.append("bloque_tema_cd", this.bloque_tema_cd);
      if (this.bloque_descripcion) {
        formData.append(
          "bloque_descripcion",
          this.bloque_descripcion.toUpperCase()
        );
      } else {
        formData.append("bloque_descripcion", "");
      }

      // console.log("examenid", this.bloque_examen_cd);
      if (this.bloque_tipo === "EXAM") {
        formData.append("bloque_examen_cd", this.bloque_examen_cd);
      } else {
        formData.append("bloque_examen_cd", "");
      }

      // console.log(this.bloque_tipo);
      formData.append("bloque_tipo", this.bloque_tipo);

      if (this.$refs.pondAdd.getFile(0)) {
        // console.log("bloque_fichero", this.$refs.pondAdd.getFile(0).file)
        formData.append("bloque_fichero", this.$refs.pondAdd.getFile(0).file);
      } else {
        formData.append("bloque_fichero", "");
      }
      if (this.$refs.pondEdit.getFile(0)) {
        // console.log("bloque_fichero", this.$refs.pondEdit.getFile(0).file)
        formData.append("bloque_fichero", this.$refs.pondEdit.getFile(0).file);
      } else {
        formData.append("bloque_fichero", "");
      }
      return formData;
    },
    selectTheme() {
      this.$router.push({
        path: `/profesor/inicio`,
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>